<template>
	<div class="cont">
		<div class="b">
			<div class="top">
				<img class="top_img" src="./img/hy_bj.png"></img>
				<div class="top_vi">
					<div class="top_zuo">
						<div class="zuo_h3">复试模板VIP</div>

						<div class="zuo_text" v-if="currentUser.vipOverDate">
							{{currentUser.vipOverDate}}到期
						</div>
						<div class="zuo_text" v-else>帮你复试通关上岸</div>
						<div class="zuo_img_vi">
							<img class="zuo_img" :src="currentUser.avatarFile"></img>
							{{currentUser.vipType==1?'还未开通VIP喔':'已开通'+currentUser.vipTitle}}
						</div>
					</div>
					<div class="top_you" v-if="currentUser.vipType == 1" catchtap="goPage"
						data-url="./openingVIP/index">去开通
						<div class="you_vi">
							<van-icon name="arrow" color="#FCEBCC" />
						</div>
					</div>
					<div class="top_you" v-if="currentUser.vipType == 2" catchtap="goPage"
						data-url="./openingVIP/index">去升级
						<div class="you_vi">
							<van-icon name="arrow" color="#FCEBCC" />
						</div>
					</div>
					<div class="top_you" v-if="currentUser.vipType == 3" catchtap="goPage"
						data-url="./openingVIP/index">已开通
						<div class="you_vi">
							<van-icon name="arrow" color="#FCEBCC" />
						</div>
					</div>

				</div>
			</div>
		</div>
		<img class="bdqy" src="./img/bdqy.png"></img>
		<div class="body">
			<div class="body_vi">
				<img class="jian01" src="./img/icon_jt_sz3.png"></img>
				<div class="body_li">
					<img class="li_img" src="./img/icon_fsmbtg.png"></img>
					<div class="li_h4">复试模板通关</div>
					<div class="li_text">帮你1小时搞定复试模板</div>
				</div>
		 	<img class="jian02" src="./img/icon_jt_sz1.png"></img>
			</div>
			<div class="body_vi" style="margin-top: 16px;">
				<div class="body_li">
					<img class="li_img" src="./img/icon_zwjsdz.png"></img>
					<div class="li_h4">自我介绍定制</div>
					<div class="li_text">帮你定制个性化专属模板</div>
				</div>
				<div class="body_li" style="margin-left: 16px;">
					<img class="li_img" src="./img/icon_zywdmb.png"></img>
					<div class="li_h4">自由问答模版</div>
					<div class="li_text">帮你搞定32个高频问答</div>
				</div>
			</div>
			<img class="icon_jt_sz2" src="./img/icon_jt_sz2.png"> </img>
			<div class="body_text" style="margin-top: 30px;">复试会问的问题都在这</div>
			<div class="body_text">搞定口语，直接背答案</div>
		</div>
		<div class="di">
			<!-- <div class="di_bnt btn_01" catchtap="goPage" data-url="/pages/index/unlock/index">
				<div class="bnt_h3">兑换会员</div>
				<div class="bnt_text">购买后可解锁</div>
			</div> -->
			<div class="di_bnt btn_02" catchtap="goPage" data-url="/member/openingVIP/index">
				<div class="bnt_h3" style="color: #834737;">购买会员</div>
				<div class="bnt_text" style="color: #B07E5C;">限时低价拼团</div>
			</div>
		</div>
		<div style="height: 40px;"></div>
		<van-loading class="loading" size="24px" v-if="Loadings" vertical>加载中...</van-loading>
	</div>

</template>

<script>
	import { Icon, Loading } from 'vant';
	export default {
		components: {
			[Icon.name]: Icon,
			[Loading.name]: Loading,
		},
		data() {
			return {
				Loadings:false,
				currentUser: {
					vipType: 1,
					vipOverDate: "",
					avatarFile: "",
				}
			}
		},
		created() {
			// https://eng.yanxiaobai.com/#/loginVip/?appid=wx2b13c14aae25044f
			var appid = this.$route.query.appid;
			var storage = window.sessionStorage;
			storage.setItem('appid', appid); //wx2b13c14aae25044f
			let redirect_url = encodeURIComponent(location.origin + "/#/openingVIP");
			console.log("appid", appid, redirect_url)
			let url =
			  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_base`;
			
			window.location.href = url
		},
		methods: {
			
		}
	}
</script>

<style scoped="scoped">
	.loading{
		width: 300px;
		padding: 60px 0;
		border-radius: 12px;
		position: fixed;
		left: 50%;
		top:50%;
		z-index: 10;
		background-color: #fff;
		border: 1px solid #E6E6E6;
		margin-left: -150px;
		
	}
	.cont {
		overflow: hidden;
		background: #F5F5F5;
		min-height: 100vh;
	}

	.b {
		width: 750px;
		height: 340px;
		background: linear-gradient(180deg, #AFB2B5 0%, rgba(229, 229, 230, 0) 100%);
		overflow: hidden;
	}

	.top {
		width: 670px;
		height: 232px;
		background: linear-gradient(181deg, #2A2A2A 0%, #2C2C2C 100%);
		border-radius: 20px;
		margin: 24px auto 0;
		position: relative;
	}

	.top_img {
		width: 100%;
		height: 100%;
	}

	.top_vi {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0 40px;
	}

	.zuo_h3 {
		font-size: 36px;
		color: #F4D2A2;
		font-weight: bold;
		line-height: 50px;
	}

	.zuo_text {
		font-size: 24px;
		color: #C4B49F;
		line-height: 26px;
		margin-top: 10px;
	}

	.zuo_img_vi {
		display: flex;
		align-items: center;
		margin-top: 40px;
		color: #CFC7BD;
		font-size: 24px;
	}

	.zuo_img {
		width: 54px;
		height: 54px;
		margin-right: 10px;
		border-radius: 50%;
	}

	.top_you {
		padding: 0 24px;
		height: 48px;
		background: linear-gradient(106deg, #FBE7B0 0%, #FCEBCE 100%);
		border-radius: 29px;
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 24px;
		color: #483214;
		font-weight: bold;
	}

	.you_vi {
		width: 22px;
		height: 22px;
		background: #483214;
		font-weight: bold;
		border-radius: 50%;
		font-size: 14px;
		margin-left: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bdqy {
		display: block;
		width: 670px;
		height: 40px;
		margin: -74px auto 0;
	}

	.body {
		width: 670px;
		padding: 48px 35px 48px;
		background: #FFFFFF;
		border-radius: 22px;
		box-sizing: border-box;
		margin: 32px auto;
	}

	.body_vi {
		display: flex;
		justify-content: center;
	}

	.jian01,
	.jian02 {
		width: 34px;
		height: 34px;
		margin-top: 162px;
	}

	.jian01 {
		margin-right: 40px;
	}

	.jian02 {
		margin-left: 40px;
	}

	.body_li {
		width: 312px;
		height: 200px;
		background: #F5F5F5;
		border-radius: 28px;
		text-align: center;
		box-sizing: border-box;
		padding-top: 26px;
	}

	.li_img {
		width: 70px;
		height: 70px;
	}

	.li_h4 {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
		margin-top: 10px;
	}

	.li_text {
		font-size: 24px;
		color: #B8BCBF;
		line-height: 33px;
		margin-top: 2px;
	}

	.icon_jt_sz2 {
		width: 34px;
		height: 34px;
		display: flex;
		margin: 12px auto 0;
	}

	.body_text {
		width: 550px;
		height: 48px;
		line-height: 48px;
		text-align: center;
		background: linear-gradient(270deg, #fff 0%, #FFECEE 48%, #fff 100%);
		font-size: 32px;
		font-weight: bold;
		margin: 20px auto 0;
	}
	.di{
	  margin-top: 10px;
	  padding: 0 30px;
	  display: flex;
	  justify-content: space-between;
	}
	.di_bnt{
	  width: 334px;
	  height: 98px;
	  color: #fff;
	  border-radius: 49px;
	  text-align: center;
		margin: 0 auto;
	}
	.btn_01{
	  background-color: #413B3B;
	}
	.btn_02{
		width: 634px;
	  background-color: #F9D798;
	}
	.bnt_h3{
	  font-size: 32px;
	  font-weight: 600;
	  color: #FFFFFF;
	  line-height: 45px;
	  margin-top: 8px;
	}
	.bnt_text{
	  font-size: 24px;
	  color: #CCCCCC;
	  line-height: 33px;
	}

</style>
